
// Libraries
import { Component, Vue } from "nuxt-property-decorator";
import { Products } from "~/operations/messages";

// Components
import PageJumpNavigation from "~/components/molecules/nav/PageJumpNavigation.vue";
import { generateMetaTags } from "~/operations/meta-helper-functions";

@Component({
  components: {
    PageJumpNavigation,
  },
})
export default class HeizoelSortenView extends Vue {
  head() {
    return {
      ...generateMetaTags(this.$route.path, "heizoel-sorten"),
      link: [{ rel: "canonical", href: `${this.$route.fullPath}` }],
    };
  }

  Products = Products;

  JumpNavItems = [
    {
      name: Products.normal,
      title: `Springen Sie zum Bereich für Infos zu: ${Products.normal}`,
      href: { path: "/infos/heizoel-sorten", hash: "#first-anker" },
    },
    {
      name: Products.premium,
      title: `Springen Sie zum Bereich für Infos zu: ${Products.premium}`,
      href: { path: "/infos/heizoel-sorten", hash: "#second-anker" },
    },
    {
      name: Products.climateNeutralNormal,
      title: `Springen Sie zum Bereich für Infos zu: ${Products.climateNeutralNormal}`,
      href: { path: "/infos/heizoel-sorten", hash: "#third-anker" },
    },
    {
      name: Products.climateNeutralPremium,
      title: `Springen Sie zum Bereich für Infos zu: ${Products.climateNeutralPremium}`,
      href: { path: "/infos/heizoel-sorten", hash: "#fourth-anker" },
    },
    {
      name: Products.bio10,
      title: `Springen Sie zum Bereich für Infos zu: ${Products.bio10}`,
      href: { path: "/infos/heizoel-sorten", hash: "#fifth-anker" },
    },
    {
      name: Products.bio15,
      title: `Springen Sie zum Bereich für Infos zu: ${Products.bio15}`,
      href: { path: "/infos/heizoel-sorten", hash: "#sixth-anker" },
    },
    {
      name: Products.bio10Premium,
      title: `Springen Sie zum Bereich für Infos zu: ${Products.bio10Premium}`,
      href: { path: "/infos/heizoel-sorten", hash: "#seventh-anker" },
    },
  ];
}
